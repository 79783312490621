import moment from "moment";

export const dateWithTimeFormatFunction = (date) => {
  if (date === null) {
    return 0;
  }

  return (
    moment(date).format("YYYY.MM.DD.") +
    "(" +
    moment(date).format("dddd").slice(0, 1) +
    ")" +
    " " +
    moment(date).format("hh:mm")
  );
};
