import React, { useCallback, useEffect, useState } from "react";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px6rate,
  w_px113rate,
  w_px11rate,
  w_px144rate,
  w_px25rate,
  w_px26rate,
  w_px5rate,
  w_px8rate,
} from "../../../size";
import ContentText from "../../content_text/ContentText";
import DetailTextInput from "../../detail_text_input/DetailTextInput";
import ListName from "../../list_name/ListName";
import PinkBtn from "../../pink_btn/PinkBtn";
import SubTitle from "../../sub_title/SubTitle";
import WhiteBtn from "../../white_btn/WhiteBtn";
import { putAdministratorInAdminUserManagement } from "../../../../communication_system/axios_apis/axios_apis";
import styled from "styled-components";
import RadioBtn from "../../radio_btn/RadioBtn";
import { phoneNumberCheckFunction } from "../../../../data_system/data_functions/phone_number_check_function";

const AdministratorDetailComponent = ({
  choiceItem,
  setChoiceItem,
  setBodyContents,
}) => {
  console.log(choiceItem);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("민성진");

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const [phoneNumber, setPhoneNumber] = useState("01012345678");

  const onChangePhoneNumber = useCallback((e) => {
    setPhoneNumber(e.target.value);
  }, []);

  const [certified, setCertified] = useState(0);

  const onClickCertified0 = useCallback(() => {
    setCertified(0);
  }, []);

  const onClickCertified1 = useCallback(() => {
    setCertified(1);
  }, []);

  const [blocked, setBlocked] = useState(0);

  const onClickBlocked0 = useCallback(() => {
    setBlocked(0);
  }, []);

  const onClickBlocked1 = useCallback(() => {
    setBlocked(1);
  }, []);

  useEffect(() => {
    setLoading(true);

    setCertified(
      choiceItem !== null && choiceItem !== undefined
        ? choiceItem.certified
          ? 1
          : 0
        : 0
    );
    setBlocked(
      choiceItem !== null && choiceItem !== undefined
        ? choiceItem.blocked
          ? 1
          : 0
        : 0
    );

    setLoading(false);
  }, [choiceItem]);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>관리자 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이메일 계정</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{choiceItem.email}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이름</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px113rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"이름을 입력해주세요."}
              value={name}
              onChange={onChangeName}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>휴대폰번호</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px144rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"휴대폰번호를 입력해주세요."}
              value={phoneNumber}
              onChange={onChangePhoneNumber}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px6rate,
          }}
        >
          <div
            style={{
              marginRight: w_px26rate,
            }}
          >
            <ListName>승인 상태</ListName>
          </div>
          <div>
            <div
              style={{
                marginBottom: h_px6rate,
              }}
            >
              <RadioTotalFrame>
                <RadioFrame>
                  <RadioBtn
                    checked={parseInt(certified) === 1 ? true : false}
                    onClick={onClickCertified1}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>승인</ContentText>
                  </div>
                </RadioFrame>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <RadioFrame>
                    <RadioBtn
                      checked={parseInt(certified) === 0 ? true : false}
                      onClick={onClickCertified0}
                    ></RadioBtn>
                    <div
                      style={{
                        marginLeft: w_px5rate,
                      }}
                    >
                      <ContentText>비승인</ContentText>
                    </div>
                  </RadioFrame>
                </div>
              </RadioTotalFrame>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px6rate,
          }}
        >
          <div
            style={{
              marginRight: w_px26rate,
            }}
          >
            <ListName>차단 상태</ListName>
          </div>
          <div>
            <div
              style={{
                marginBottom: h_px6rate,
              }}
            >
              <RadioTotalFrame>
                <RadioFrame>
                  <RadioBtn
                    checked={parseInt(blocked) === 0 ? true : false}
                    onClick={onClickBlocked0}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>정상</ContentText>
                  </div>
                </RadioFrame>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <RadioFrame>
                    <RadioBtn
                      checked={parseInt(blocked) === 1 ? true : false}
                      onClick={onClickBlocked1}
                    ></RadioBtn>
                    <div
                      style={{
                        marginLeft: w_px5rate,
                      }}
                    >
                      <ContentText>차단</ContentText>
                    </div>
                  </RadioFrame>
                </div>
              </RadioTotalFrame>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WhiteBtn>취소</WhiteBtn>
          <div
            style={{
              marginLeft: w_px8rate,
            }}
          >
            <PinkBtn
              onClick={async () => {
                if (name === "") {
                  alert("이름을 입력해주세요.");
                } else {
                  if (phoneNumber === "") {
                    alert("휴대폰번호를 입력해주세요.");
                  } else {
                    if (phoneNumberCheckFunction.test(phoneNumber) === false) {
                      alert("휴대폰번호 형식이 올바르지 않습니다.");
                    } else {
                      const response =
                        await putAdministratorInAdminUserManagement(
                          certified,
                          blocked,
                          name,
                          phoneNumber
                        );

                      if (response.status === 200) {
                        alert("수정되었습니다.");
                        setBodyContents((prevContents) =>
                          prevContents.map((content) => {
                            if (content.id === choiceItem.id) {
                              return {
                                ...content,
                                certified: certified,
                                blocked: blocked,
                                name: name,
                                phoneNumber: phoneNumber,
                              };
                            }
                            return content;
                          })
                        );
                        setChoiceItem((prevItem) => {
                          return {
                            ...prevItem,
                            certified: certified,
                            blocked: blocked,
                            name: name,
                            phoneNumber: phoneNumber,
                          };
                        });
                      }
                    }
                  }
                }
              }}
            >
              수정
            </PinkBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdministratorDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
