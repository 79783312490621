import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  h_px12rate,
  h_px14rate,
  h_px25rate,
  h_px6rate,
  w_px25rate,
  w_px26rate,
  w_px5rate,
  w_px8rate,
} from "../../../size";
import RadioBtn from "../../radio_btn/RadioBtn";
import SubTitle from "../../sub_title/SubTitle";
import ListName from "../../list_name/ListName";
import ContentText from "../../content_text/ContentText";
import WhiteBtn from "../../white_btn/WhiteBtn";
import PinkBtn from "../../pink_btn/PinkBtn";
import { putAdvertiserAdvertisementManagementInAdmin } from "../../../../communication_system/axios_apis/axios_apis";
import { numberCommaFormatFunction } from "../../../../data_system/data_functions/number_comma_format_function";

const AdvertiserAdvertisementManagementDetailComponent = ({
  choiceItem,
  setChoiceItem,
  setBodyContents,
}) => {
  console.log(choiceItem);

  const administratorAdvertisementProductName =
    choiceItem.administratorAdvertisementProductId === 1
      ? "N스토어 트래픽 - 단독"
      : choiceItem.administratorAdvertisementProductId === 2
      ? "N스토어 트래픽 - 가격비교"
      : choiceItem.administratorAdvertisementProductId === 3
      ? "N스토어 알림"
      : choiceItem.administratorAdvertisementProductId === 4
      ? "N스토어 상품 찜"
      : choiceItem.administratorAdvertisementProductId === 5
      ? "N플레이스 트래픽"
      : choiceItem.administratorAdvertisementProductId === 6
      ? "N플레이스 저장"
      : choiceItem.administratorAdvertisementProductId === 7
      ? "N플레이스 알림"
      : choiceItem.administratorAdvertisementProductId === 8
      ? "N플레이스 지도 찜"
      : choiceItem.administratorAdvertisementProductId === 9
      ? "N플레이스 블로그 공유"
      : choiceItem.administratorAdvertisementProductId === 10
      ? "N플레이스 Keep 공유"
      : choiceItem.administratorAdvertisementProductId === 11
      ? "N플레이스 블로그 리뷰"
      : choiceItem.administratorAdvertisementProductId === 12
      ? "카카오 선물 위시"
      : choiceItem.administratorAdvertisementProductId === 13
      ? "카카오 선물 후기 좋아요"
      : choiceItem.administratorAdvertisementProductId === 14
      ? "카카오 맵 저장"
      : choiceItem.administratorAdvertisementProductId === 15
      ? "구글 맵 저장"
      : choiceItem.administratorAdvertisementProductId === 16
      ? "구글 맵 리뷰"
      : choiceItem.administratorAdvertisementProductId === 17
      ? "인스타 팔로우"
      : choiceItem.administratorAdvertisementProductId === 18
      ? "인스타 좋아요"
      : choiceItem.administratorAdvertisementProductId === 19
      ? "인스타 저장"
      : choiceItem.administratorAdvertisementProductId === 20
      ? "유튜브 구독"
      : choiceItem.administratorAdvertisementProductId === 21
      ? "유튜브 좋아요"
      : choiceItem.administratorAdvertisementProductId === 22
      ? "배민 찜"
      : choiceItem.administratorAdvertisementProductId === 23
      ? "배민 원 찜"
      : "";

  const totalWorkload =
    choiceItem.administratorAdvertisementProductId === 1
      ? choiceItem.NStoreTrafficSingle.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 2
      ? choiceItem.NStoreTrafficPriceComparison.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 3
      ? choiceItem.NStoreAlarm.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 4
      ? choiceItem.NStoreProductJjim.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 5
      ? choiceItem.NPlaceTraffic.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 6
      ? choiceItem.NPlaceSave.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 7
      ? choiceItem.NPlaceAlarm.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 8
      ? choiceItem.NPlaceMapJjim.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 9
      ? choiceItem.NPlaceBlogShare.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 10
      ? choiceItem.NPlaceKeepShare.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 11
      ? choiceItem.NPlaceBlogReview.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 12
      ? choiceItem.KakaoPresentWish.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 13
      ? choiceItem.KakaoPresentReviewLike.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 14
      ? choiceItem.KakaoMapSave.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 15
      ? choiceItem.GoogleMapSave.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 16
      ? choiceItem.GoogleMapReview.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 17
      ? choiceItem.InstaFollow.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 18
      ? choiceItem.InstaLike.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 19
      ? choiceItem.InstaSave.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 20
      ? choiceItem.YoutubeSubscribe.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 21
      ? choiceItem.YoutubeLike.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 22
      ? choiceItem.BaeminJjim.totalWorkload
      : choiceItem.administratorAdvertisementProductId === 23
      ? choiceItem.BaeminOneJjim.totalWorkload
      : "";

  const todayTargetDailyWorkload =
    choiceItem.administratorAdvertisementProductId === 1
      ? choiceItem.NStoreTrafficSingle.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 2
      ? choiceItem.NStoreTrafficPriceComparison.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 3
      ? choiceItem.NStoreAlarm.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 4
      ? choiceItem.NStoreProductJjim.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 5
      ? choiceItem.NPlaceTraffic.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 6
      ? choiceItem.NPlaceSave.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 7
      ? choiceItem.NPlaceAlarm.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 8
      ? choiceItem.NPlaceMapJjim.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 9
      ? choiceItem.NPlaceBlogShare.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 10
      ? choiceItem.NPlaceKeepShare.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 11
      ? choiceItem.NPlaceBlogReview.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 12
      ? choiceItem.KakaoPresentWish.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 13
      ? choiceItem.KakaoPresentReviewLike.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 14
      ? choiceItem.KakaoMapSave.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 15
      ? choiceItem.GoogleMapSave.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 16
      ? choiceItem.GoogleMapReview.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 17
      ? choiceItem.InstaFollow.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 18
      ? choiceItem.InstaLike.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 19
      ? choiceItem.InstaSave.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 20
      ? choiceItem.YoutubeSubscribe.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 21
      ? choiceItem.YoutubeLike.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 22
      ? choiceItem.BaeminJjim.todayTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 23
      ? choiceItem.BaeminOneJjim.todayTargetDailyWorkload
      : "";

  const todayCompleteDailyWorkload =
    choiceItem.administratorAdvertisementProductId === 1
      ? choiceItem.NStoreTrafficSingle.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 2
      ? choiceItem.NStoreTrafficPriceComparison.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 3
      ? choiceItem.NStoreAlarm.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 4
      ? choiceItem.NStoreProductJjim.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 5
      ? choiceItem.NPlaceTraffic.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 6
      ? choiceItem.NPlaceSave.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 7
      ? choiceItem.NPlaceAlarm.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 8
      ? choiceItem.NPlaceMapJjim.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 9
      ? choiceItem.NPlaceBlogShare.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 10
      ? choiceItem.NPlaceKeepShare.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 11
      ? choiceItem.NPlaceBlogReview.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 12
      ? choiceItem.KakaoPresentWish.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 13
      ? choiceItem.KakaoPresentReviewLike.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 14
      ? choiceItem.KakaoMapSave.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 15
      ? choiceItem.GoogleMapSave.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 16
      ? choiceItem.GoogleMapReview.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 17
      ? choiceItem.InstaFollow.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 18
      ? choiceItem.InstaLike.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 19
      ? choiceItem.InstaSave.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 20
      ? choiceItem.YoutubeSubscribe.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 21
      ? choiceItem.YoutubeLike.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 22
      ? choiceItem.BaeminJjim.todayCompleteDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 23
      ? choiceItem.BaeminOneJjim.todayCompleteDailyWorkload
      : "";

  const nextTargetDailyWorkload =
    choiceItem.administratorAdvertisementProductId === 1
      ? choiceItem.NStoreTrafficSingle.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 2
      ? choiceItem.NStoreTrafficPriceComparison.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 3
      ? choiceItem.NStoreAlarm.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 4
      ? choiceItem.NStoreProductJjim.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 5
      ? choiceItem.NPlaceTraffic.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 6
      ? choiceItem.NPlaceSave.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 7
      ? choiceItem.NPlaceAlarm.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 8
      ? choiceItem.NPlaceMapJjim.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 9
      ? choiceItem.NPlaceBlogShare.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 10
      ? choiceItem.NPlaceKeepShare.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 11
      ? choiceItem.NPlaceBlogReview.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 12
      ? choiceItem.KakaoPresentWish.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 13
      ? choiceItem.KakaoPresentReviewLike.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 14
      ? choiceItem.KakaoMapSave.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 15
      ? choiceItem.GoogleMapSave.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 16
      ? choiceItem.GoogleMapReview.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 17
      ? choiceItem.InstaFollow.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 18
      ? choiceItem.InstaLike.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 19
      ? choiceItem.InstaSave.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 20
      ? choiceItem.YoutubeSubscribe.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 21
      ? choiceItem.YoutubeLike.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 22
      ? choiceItem.BaeminJjim.nextTargetDailyWorkload
      : choiceItem.administratorAdvertisementProductId === 23
      ? choiceItem.BaeminOneJjim.nextTargetDailyWorkload
      : "";

  const todayAdvertisementProgressType =
    choiceItem.administratorAdvertisementProductId === 1
      ? choiceItem.NStoreTrafficSingle.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 2
      ? choiceItem.NStoreTrafficPriceComparison.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 3
      ? choiceItem.NStoreAlarm.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 4
      ? choiceItem.NStoreProductJjim.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 5
      ? choiceItem.NPlaceTraffic.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 6
      ? choiceItem.NPlaceSave.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 7
      ? choiceItem.NPlaceAlarm.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 8
      ? choiceItem.NPlaceMapJjim.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 9
      ? choiceItem.NPlaceBlogShare.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 10
      ? choiceItem.NPlaceKeepShare.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 11
      ? choiceItem.NPlaceBlogReview.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 12
      ? choiceItem.KakaoPresentWish.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 13
      ? choiceItem.KakaoPresentReviewLike.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 14
      ? choiceItem.KakaoMapSave.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 15
      ? choiceItem.GoogleMapSave.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 16
      ? choiceItem.GoogleMapReview.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 17
      ? choiceItem.InstaFollow.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 18
      ? choiceItem.InstaLike.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 19
      ? choiceItem.InstaSave.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 20
      ? choiceItem.YoutubeSubscribe.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 21
      ? choiceItem.YoutubeLike.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 22
      ? choiceItem.BaeminJjim.todayAdvertisementProgressType
      : choiceItem.administratorAdvertisementProductId === 23
      ? choiceItem.BaeminOneJjim.todayAdvertisementProgressType
      : "";

  const todayAdvertisementProgressTypeName =
    todayAdvertisementProgressType === 1 ? "실행" : "중단";

  const [nextAdvertisementProgressType, setNextAdvertisementProgressType] =
    useState(
      choiceItem.administratorAdvertisementProductId === 1
        ? choiceItem.NStoreTrafficSingle.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 2
        ? choiceItem.NStoreTrafficPriceComparison.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 3
        ? choiceItem.NStoreAlarm.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 4
        ? choiceItem.NStoreProductJjim.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 5
        ? choiceItem.NPlaceTraffic.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 6
        ? choiceItem.NPlaceSave.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 7
        ? choiceItem.NPlaceAlarm.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 8
        ? choiceItem.NPlaceMapJjim.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 9
        ? choiceItem.NPlaceBlogShare.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 10
        ? choiceItem.NPlaceKeepShare.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 11
        ? choiceItem.NPlaceBlogReview.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 12
        ? choiceItem.KakaoPresentWish.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 13
        ? choiceItem.KakaoPresentReviewLike.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 14
        ? choiceItem.KakaoMapSave.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 15
        ? choiceItem.GoogleMapSave.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 16
        ? choiceItem.GoogleMapReview.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 17
        ? choiceItem.InstaFollow.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 18
        ? choiceItem.InstaLike.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 19
        ? choiceItem.InstaSave.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 20
        ? choiceItem.YoutubeSubscribe.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 21
        ? choiceItem.YoutubeLike.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 22
        ? choiceItem.BaeminJjim.nextAdvertisementProgressType
        : choiceItem.administratorAdvertisementProductId === 23
        ? choiceItem.BaeminOneJjim.nextAdvertisementProgressType
        : ""
    );

  const onClickNextAdvertisementProgressType1 = useCallback(() => {
    setNextAdvertisementProgressType(1);
  }, []);

  const onClickNextAdvertisementProgressType2 = useCallback(() => {
    setNextAdvertisementProgressType(2);
  }, []);

  return (
    <div
      style={{
        paddingTop: h_px25rate,
        paddingLeft: w_px25rate,
      }}
    >
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>광고주 광고 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>광고주 아이디</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.email}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>사업자명</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.email}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>사업자등록번호</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {choiceItem.Advertiser.businessRegistrationNumber}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>휴대폰 번호</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText> {choiceItem.Advertiser.phoneNumber}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>광고 상품명</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{administratorAdvertisementProductName}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>총 작업량</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {numberCommaFormatFunction(totalWorkload)}개
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>오늘 목표 작업량</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {numberCommaFormatFunction(todayTargetDailyWorkload)}개
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>오늘 달성 작업량</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {numberCommaFormatFunction(todayCompleteDailyWorkload)}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>내일 목표 작업량</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {numberCommaFormatFunction(nextTargetDailyWorkload)}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>오늘 실행 상태</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{todayAdvertisementProgressTypeName}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px6rate,
        }}
      >
        <div
          style={{
            marginRight: w_px26rate,
          }}
        >
          <ListName>내일 실행 상태</ListName>
        </div>
        <div>
          <div
            style={{
              marginBottom: h_px6rate,
            }}
          >
            <RadioTotalFrame>
              <RadioFrame>
                <RadioBtn
                  checked={
                    parseInt(nextAdvertisementProgressType) === 1 ? true : false
                  }
                  onClick={onClickNextAdvertisementProgressType1}
                ></RadioBtn>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <ContentText>실행</ContentText>
                </div>
              </RadioFrame>
              <div
                style={{
                  marginLeft: w_px5rate,
                }}
              >
                <RadioFrame>
                  <RadioBtn
                    checked={
                      parseInt(nextAdvertisementProgressType) === 2
                        ? true
                        : false
                    }
                    onClick={onClickNextAdvertisementProgressType2}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>중단</ContentText>
                  </div>
                </RadioFrame>
              </div>
            </RadioTotalFrame>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: h_px12rate,
        }}
      >
        <WhiteBtn onClick={async () => {}}>취소</WhiteBtn>
        <div
          style={{
            marginLeft: w_px8rate,
          }}
        >
          <PinkBtn
            onClick={async () => {
              const response =
                await putAdvertiserAdvertisementManagementInAdmin(
                  choiceItem.id,
                  nextAdvertisementProgressType
                );

              if (response.status === 200) {
                alert("수정되었습니다.");

                const getProductKey = (administratorAdvertisementProductId) => {
                  switch (administratorAdvertisementProductId) {
                    case 1:
                      return "NStoreTrafficSingle";
                    case 2:
                      return "NStoreTrafficPriceComparison";
                    case 3:
                      return "NStoreAlarm";
                    case 4:
                      return "NStoreProductJjim";
                    case 5:
                      return "NPlaceTraffic";
                    case 6:
                      return "NPlaceSave";
                    case 7:
                      return "NPlaceAlarm";
                    case 8:
                      return "NPlaceMapJjim";
                    case 9:
                      return "NPlaceBlogShare";
                    case 10:
                      return "NPlaceKeepShare";
                    case 11:
                      return "NPlaceBlogReview";
                    case 12:
                      return "KakaoPresentWish";
                    case 13:
                      return "KakaoPresentReviewLike";
                    case 14:
                      return "KakaoMapSave";
                    case 15:
                      return "GoogleMapSave";
                    case 16:
                      return "GoogleMapReview";
                    case 17:
                      return "InstaFollow";
                    case 18:
                      return "InstaLike";
                    case 19:
                      return "InstaSave";
                    case 20:
                      return "YoutubeSubscribe";
                    case 21:
                      return "YoutubeLike";
                    case 22:
                      return "BaeminJjim";
                    case 23:
                      return "BaeminOneJjim";
                    default:
                      return "";
                  }
                };

                setBodyContents((prevContents) =>
                  prevContents.map((content) => {
                    if (content.id === choiceItem.id) {
                      return {
                        ...content,
                        [getProductKey(
                          choiceItem.administratorAdvertisementProductId
                        )]: {
                          ...content[
                            getProductKey(
                              choiceItem.administratorAdvertisementProductId
                            )
                          ],
                          nextAdvertisementProgressType,
                        },
                      };
                    }
                    return content;
                  })
                );

                setChoiceItem((prevItem) => ({
                  ...prevItem,
                  [getProductKey(prevItem.administratorAdvertisementProductId)]:
                    {
                      ...prevItem[
                        getProductKey(
                          prevItem.administratorAdvertisementProductId
                        )
                      ],
                      nextAdvertisementProgressType,
                    },
                }));
              }
            }}
          >
            수정
          </PinkBtn>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvertiserAdvertisementManagementDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
