import React, { useCallback, useState } from "react";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px6rate,
  w_px11rate,
  w_px145rate,
  w_px25rate,
  w_px26rate,
  w_px5rate,
  w_px8rate,
} from "../../../size";
import DetailTextInput from "../../detail_text_input/DetailTextInput";
import ListName from "../../list_name/ListName";
import PinkBtn from "../../pink_btn/PinkBtn";
import SubTitle from "../../sub_title/SubTitle";
import WhiteBtn from "../../white_btn/WhiteBtn";
import styled from "styled-components";
import RadioBtn from "../../radio_btn/RadioBtn";
import ContentText from "../../content_text/ContentText";
import { isOnlyNumber } from "../../../../data_system/data_functions/is_only_number";
import { postAdministratorAdvertisementProductInAdmin } from "../../../../communication_system/axios_apis/axios_apis";

const AdvertisementProductManagementDetailRegister = ({
  bodyContents,
  setBodyContents,
}) => {
  const [
    administratorAdvertisementProductName,
    setAdministratorAdvertisementProductName,
  ] = useState("");

  const [nextAdvertiserPrice, setNextAdvertiserPrice] = useState("");

  const [userPrice, setUserPrice] = useState("");

  const [advertisementProgressType, setAdvertisementProgressType] = useState(2);

  const onClickAdvertisementProgressType1 = useCallback(() => {
    setAdvertisementProgressType(1);
  }, []);

  const onClickAdvertisementProgressType2 = useCallback(() => {
    setAdvertisementProgressType(2);
  }, []);

  return (
    <div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>광고 상품 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>광고 상품명</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px145rate}
              height={h_px16rate}
              font_size={w_px11rate}
              value={administratorAdvertisementProductName}
              onChange={(e) => {
                setAdministratorAdvertisementProductName(e.target.value);
              }}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>내일 광고주 판매 가격</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px145rate}
              height={h_px16rate}
              font_size={w_px11rate}
              value={nextAdvertiserPrice}
              onChange={(e) => {
                if (
                  isOnlyNumber.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setNextAdvertiserPrice(e.target.value);
                }
              }}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>유저 지급 가격</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px145rate}
              height={h_px16rate}
              font_size={w_px11rate}
              value={userPrice}
              onChange={(e) => {
                if (
                  isOnlyNumber.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setUserPrice(e.target.value);
                }
              }}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px6rate,
          }}
        >
          <div
            style={{
              marginRight: w_px26rate,
            }}
          >
            <ListName>광고 상품 진행 상태</ListName>
          </div>
          <div>
            <div
              style={{
                marginBottom: h_px6rate,
              }}
            >
              <RadioTotalFrame>
                <RadioFrame>
                  <RadioBtn
                    checked={
                      parseInt(advertisementProgressType) === 1 ? true : false
                    }
                    onClick={onClickAdvertisementProgressType1}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>진행</ContentText>
                  </div>
                </RadioFrame>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <RadioFrame>
                    <RadioBtn
                      checked={
                        parseInt(advertisementProgressType) === 2 ? true : false
                      }
                      onClick={onClickAdvertisementProgressType2}
                    ></RadioBtn>
                    <div
                      style={{
                        marginLeft: w_px5rate,
                      }}
                    >
                      <ContentText>중단</ContentText>
                    </div>
                  </RadioFrame>
                </div>
              </RadioTotalFrame>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WhiteBtn>취소</WhiteBtn>
          <div
            style={{
              marginLeft: w_px8rate,
            }}
          >
            <PinkBtn
              onClick={async () => {
                if (administratorAdvertisementProductName === "") {
                  alert("광고 상품명을 입력해주세요.");
                } else {
                  if (nextAdvertiserPrice === "") {
                    alert("내일 광고주 판매 포인트를 입력해주세요.");
                  } else {
                    if (userPrice === "") {
                      alert("유저 지급 가격을 입력해주세요.");
                    } else {
                      const response =
                        await postAdministratorAdvertisementProductInAdmin(
                          administratorAdvertisementProductName,
                          0,
                          nextAdvertiserPrice,
                          userPrice,
                          advertisementProgressType
                        );

                      console.log(response);
                      if (response.status === 201) {
                        alert("광고 상품이 등록되었습니다.");
                        if (bodyContents.length < 10) {
                          setBodyContents((pre) => {
                            return [
                              ...pre,
                              response.data
                                .newAdministratorAdvertisementProduct,
                            ];
                          });
                          setAdministratorAdvertisementProductName("");
                          setNextAdvertiserPrice("");
                          setUserPrice("");
                          setAdvertisementProgressType(2);
                        }
                      }
                    }
                  }
                }
              }}
            >
              등록
            </PinkBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvertisementProductManagementDetailRegister);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
