import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { rgb_112_112_112, rgb_255_255_255, rgb_66_139_247 } from "../../colors";
import { ArrowBackwardGray1x, ArrowForwardGray1x } from "../../icons/icons";
import { h_px3rate, h_px40rate, w_px10rate, w_px20rate } from "../../size";

const Pagination = ({
  totalPages,
  limit,
  page,
  setPage,
  setItemOpen,
  setChoiceRowIndex,
}) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);

  useEffect(() => {
    // 현재 페이지 그룹의 시작 페이지 번호를 계산
    const startPage = Math.floor((page - 1) / limit) * limit + 1;
    // 현재 페이지 그룹의 마지막 페이지 번호를 계산
    const endPage = Math.min(startPage + limit - 1, totalPages);

    // 현재 페이지 그룹에 해당하는 페이지 번호들을 계산
    const currentPages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    setCurrentPageArray(currentPages);
  }, [page, totalPages, limit]);

  const go_to_first = useCallback(() => {
    setPage(1);
    setItemOpen(false);
    setChoiceRowIndex(null);
  }, [setPage, setItemOpen, setChoiceRowIndex]);

  const go_to_last = useCallback(() => {
    setPage(totalPages);
    setItemOpen(false);
    setChoiceRowIndex(null);
  }, [totalPages, setPage, setItemOpen, setChoiceRowIndex]);

  const go_to_page = useCallback(
    (num) => {
      setPage(num);
      setItemOpen(false);
      setChoiceRowIndex(null);
    },
    [setPage, setItemOpen, setChoiceRowIndex]
  );

  const back_page = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
      setItemOpen(false);
      setChoiceRowIndex(null);
    }
  }, [page, setPage, setItemOpen, setChoiceRowIndex]);

  const next_page = useCallback(() => {
    if (page < totalPages) {
      setPage(page + 1);
      setItemOpen(false);
      setChoiceRowIndex(null);
    }
  }, [page, totalPages, setPage, setItemOpen, setChoiceRowIndex]);

  return (
    <PaginationFrame>
      <div
        style={{
          marginRight: w_px20rate,
        }}
      >
        <BtnFirst
          onClick={(e) => {
            e.preventDefault();

            go_to_first();
          }}
          disabled={page === 1}
        >
          처음
        </BtnFirst>
      </div>

      <div
        style={{
          marginTop: h_px3rate,
          marginRight: w_px20rate,
        }}
      >
        <BtnLeft
          onClick={(e) => {
            e.preventDefault();

            back_page();
          }}
          disabled={page === 1}
        >
          <ArrowBackwardGray1x></ArrowBackwardGray1x>
        </BtnLeft>
      </div>

      {currentPageArray?.map((page_index, index) => {
        return (
          <div
            key={index}
            style={{
              marginRight: w_px20rate,
            }}
          >
            <BtnPageNum
              key={page_index}
              current={page === page_index}
              onClick={(e) => {
                e.preventDefault();

                go_to_page(page_index);
              }}
            >
              {page_index}
            </BtnPageNum>
          </div>
        );
      })}

      <div
        style={{
          marginTop: h_px3rate,
        }}
      >
        <BtnRight
          onClick={(e) => {
            e.preventDefault();

            next_page();
          }}
          disabled={page === totalPages}
        >
          <ArrowForwardGray1x></ArrowForwardGray1x>
        </BtnRight>
      </div>

      <div
        style={{
          marginLeft: w_px20rate,
        }}
      >
        <BtnLast
          onClick={(e) => {
            e.preventDefault();

            go_to_last();
          }}
          disabled={page === totalPages}
        >
          마지막
        </BtnLast>
      </div>
    </PaginationFrame>
  );
};

export default React.memo(Pagination);

const PaginationFrame = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${h_px40rate}px;
`;

const BtnFirst = styled.button`
  background-color: ${rgb_255_255_255};
  border-width: 0px;
  font-size: ${w_px10rate}px;
  font-family: noto_sans_kr_medium;
  color: ${rgb_112_112_112};
`;

const BtnLast = styled.button`
  background-color: ${rgb_255_255_255};
  border-width: 0px;
  font-size: ${w_px10rate}px;
  font-family: noto_sans_kr_medium;
  color: ${rgb_112_112_112};
`;

const BtnPageNum = styled.button`
  background-color: ${rgb_255_255_255};
  border-width: 0px;
  font-size: ${w_px10rate}px;
  font-family: noto_sans_kr_medium;
  color: ${(props) => (props.current ? rgb_66_139_247 : rgb_112_112_112)};
`;

const BtnLeft = styled.button`
  background-color: ${rgb_255_255_255};
  border-width: 0px;
`;

const BtnRight = styled.button`
  background-color: ${rgb_255_255_255};
  border-width: 0px;
`;
