import React, { createContext, useState, useContext } from "react";
import {
  administratorLogIn,
  administratorSignUp,
} from "../../../communication_system/axios_apis/axios_apis";
import axiosMiddleware from "../../../communication_system/axios_apis/axios_middleware/axios_middleware";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = window.localStorage.getItem("isLoggedIn");
    return storedValue !== null ? JSON.parse(storedValue) : false;
  });

  const handleSignUp = async (
    email,
    password,
    name,
    phoneNumber,
    administratorRoleTypeId,
    certified,
    blocked
  ) => {
    const response = await administratorSignUp(
      email,
      password,
      name,
      phoneNumber,
      administratorRoleTypeId,
      certified,
      blocked
    );
    console.log(response);
    if (parseInt(response.status) === 201) {
      login(response.accessToken, response.refreshToken);
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      };
    }
  };

  const handleLogin = async (email, password) => {
    const response = await administratorLogIn(email, password);
    console.log(response);
    if (parseInt(response.status) === 200) {
      login(response.accessToken, response.refreshToken);
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 401) {
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 403) {
      return {
        status: response.status,
      };
    }
  };

  const login = (accessToken, refreshToken) => {
    console.log(accessToken, refreshToken);
    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("isLoggedIn", true);

    axiosMiddleware.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;

    setIsLoggedIn(true);
  };

  const logout = () => {
    window.localStorage.setItem("isLoggedIn", false);
    setIsLoggedIn(false);
  };

  const value = {
    isLoggedIn,
    handleSignUp,
    handleLogin,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
