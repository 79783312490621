import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px6rate,
  w_px11rate,
  w_px145rate,
  w_px25rate,
  w_px26rate,
  w_px5rate,
  w_px8rate,
} from "../../../size";
import RadioBtn from "../../radio_btn/RadioBtn";
import DetailTextInput from "../../detail_text_input/DetailTextInput";
import SubTitle from "../../sub_title/SubTitle";
import ListName from "../../list_name/ListName";
import ContentText from "../../content_text/ContentText";
import WhiteBtn from "../../white_btn/WhiteBtn";
import PinkBtn from "../../pink_btn/PinkBtn";
import { isOnlyNumber } from "../../../../data_system/data_functions/is_only_number";
import { putAdministratorAdvertisementProductInAdmin } from "../../../../communication_system/axios_apis/axios_apis";

const AdvertisementProductManagementDetailComponent = ({
  choiceItem,
  setChoiceItem,
  setBodyContents,
}) => {
  console.log(choiceItem);
  const [loading, setLoading] = useState(true);

  const [nextAdvertiserPrice, setNextAdvertiserPrice] = useState(
    choiceItem.nextAdvertiserPrice
  );

  const [userPrice, setUserPrice] = useState(choiceItem.userPrice);

  const [advertisementProgressType, setAdvertisementProgressType] = useState(
    choiceItem.advertisementProgressType
  );

  const onClickAdvertisementProgressType1 = useCallback(() => {
    setAdvertisementProgressType(1);
  }, []);

  const onClickAdvertisementProgressType2 = useCallback(() => {
    setAdvertisementProgressType(2);
  }, []);

  return (
    <div
      style={{
        paddingTop: h_px25rate,
        paddingLeft: w_px25rate,
      }}
    >
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>광고 상품 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>광고 상품명</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {choiceItem.administratorAdvertisementProductName}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>오늘 광고주 판매 가격</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.todayAdvertiserPrice}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>내일 광고주 판매 포인트</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <DetailTextInput
            width={w_px145rate}
            height={h_px16rate}
            font_size={w_px11rate}
            value={nextAdvertiserPrice}
            onChange={(e) => {
              if (
                isOnlyNumber.test(e.target.value) === true ||
                e.target.value === ""
              ) {
                setNextAdvertiserPrice(e.target.value);
              }
            }}
          ></DetailTextInput>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>유저 지급 가격</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <DetailTextInput
            width={w_px145rate}
            height={h_px16rate}
            font_size={w_px11rate}
            value={userPrice}
            onChange={(e) => {
              if (
                isOnlyNumber.test(e.target.value) === true ||
                e.target.value === ""
              ) {
                setUserPrice(e.target.value);
              }
            }}
          ></DetailTextInput>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px6rate,
        }}
      >
        <div
          style={{
            marginRight: w_px26rate,
          }}
        >
          <ListName>광고 상품 진행 상태</ListName>
        </div>
        <div>
          <div
            style={{
              marginBottom: h_px6rate,
            }}
          >
            <RadioTotalFrame>
              <RadioFrame>
                <RadioBtn
                  checked={
                    parseInt(advertisementProgressType) === 1 ? true : false
                  }
                  onClick={onClickAdvertisementProgressType1}
                ></RadioBtn>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <ContentText>진행</ContentText>
                </div>
              </RadioFrame>
              <div
                style={{
                  marginLeft: w_px5rate,
                }}
              >
                <RadioFrame>
                  <RadioBtn
                    checked={
                      parseInt(advertisementProgressType) === 2 ? true : false
                    }
                    onClick={onClickAdvertisementProgressType2}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>중단</ContentText>
                  </div>
                </RadioFrame>
              </div>
            </RadioTotalFrame>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: h_px12rate,
        }}
      >
        <WhiteBtn onClick={async () => {}}>취소</WhiteBtn>
        <div
          style={{
            marginLeft: w_px8rate,
          }}
        >
          <PinkBtn
            onClick={async () => {
              if (nextAdvertiserPrice === "") {
                alert("내일 광고주 판매 포인트를 입력해주세요.");
              } else {
                if (userPrice === "") {
                  alert("유저 지급 가격을 입력해주세요.");
                } else {
                  const response =
                    await putAdministratorAdvertisementProductInAdmin(
                      choiceItem.id,
                      nextAdvertiserPrice,
                      userPrice,
                      advertisementProgressType
                    );

                  if (response.status === 200) {
                    alert("수정되었습니다.");
                    setBodyContents((prevContents) =>
                      prevContents.map((content) => {
                        if (content.id === choiceItem.id) {
                          return {
                            ...content,
                            nextAdvertiserPrice: parseInt(nextAdvertiserPrice),
                            userPrice: parseInt(userPrice),
                            advertisementProgressType:
                              advertisementProgressType,
                          };
                        }
                        return content;
                      })
                    );
                    setChoiceItem((prevItem) => {
                      return {
                        ...prevItem,
                        nextAdvertiserPrice: parseInt(nextAdvertiserPrice),
                        userPrice: parseInt(userPrice),
                        advertisementProgressType: advertisementProgressType,
                      };
                    });
                  }
                }
              }
            }}
          >
            수정
          </PinkBtn>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvertisementProductManagementDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
