import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  h_px6rate,
  w_px11rate,
  w_px160rate,
  w_px28rate,
  w_px8rate,
} from "../../design_system/size";
import {
  rgba_66_139_247_0d2,
  rgb_0_0_0,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../design_system/colors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/contexts/auth_context/auth_context";
import { sign_up_router_url } from "../../communication_system/inner_router_url/inner_router_url";
import { emailCheckFunction } from "../../data_system/data_functions/email_check_function";

function Login() {
  let navigate = useNavigate();
  const { handleLogin, logout } = useAuth();
  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setLoading(false);
  }, []);

  async function onClickLogin() {
    try {
    } catch (error) {
      console.log(error);
      alert("로그인 또는 비밀번호가 틀립니다.");
    }
  }

  useEffect(() => {
    logout();
  }, []);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <Container>
      <Header>
        <HeaderRight>
          <BtnRounded
            type="button"
            onClick={() => {
              navigate("/");
            }}
          >
            로그인
          </BtnRounded>
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <BtnRounded
              type="button"
              onClick={() => {
                navigate(sign_up_router_url);
              }}
            >
              회원가입
            </BtnRounded>
          </div>
        </HeaderRight>
      </Header>
      <LoginContainer>
        <Input
          placeholder="아이디"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Btn
          type="button"
          onClick={async () => {
            if (email === "") {
              alert("아이디를 입력해주세요.");
            } else {
              if (emailCheckFunction.test(email) === false) {
                alert("이메일 형식이 아닙니다.");
              } else {
                if (password === "") {
                  alert("비밀번호를 입력해주세요.");
                } else {
                  const get = await handleLogin(email, password);

                  if (get.status === 200) {
                    navigate("/after-login/user");
                  } else if (get.status === 400) {
                    alert("아이디 또는 비밀번호가 틀립니다.");
                  } else if (get.status === 401) {
                    alert("승인되지 않은 사용자입니다.");
                  } else if (get.status === 403) {
                    alert("차단된 사용자입니다.");
                  }
                }
              }
            }
          }}
        >
          로그인
        </Btn>
      </LoginContainer>
    </Container>
  );
}

export default React.memo(Login);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #f5f5f5;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  display: block;
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${w_px11rate}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const Btn = styled.button`
  display: block;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border-width: 0px;
  background-color: ${rgb_66_139_247};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
  cursor: pointer;
`;

const BtnRounded = styled.button`
  display: block;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border-width: 0px;
  background-color: ${rgb_66_139_247};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
  cursor: pointer;
  border-radius: 5px;
`;
