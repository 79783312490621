export const index_router_url = "/";
export const sign_up_router_url = "/sign-up";
export const user_router_url = "user";
export const advertiser_router_url = "advertiser";
export const administrator_router_url = "administrator";
export const advertisement_mission_doing_management_router_url =
  "advertisement_mission_doing_management";
export const advertisement_product_management_router_url =
  "advertisement_product_management";
export const advertiser_advertisement_management_router_url =
  "advertiser_advertisement_management";
export const user_point_history_management_router_url =
  "user_point_history_management";
export const advertiser_point_history_management_router_url =
  "advertiser_point_history_management";
export const advertiser_payment_history_management_router_url =
  "advertiser_payment_history_management";
export const user_statistical_analysis_management_router_url =
  "user_statistical_analysis_management";
export const advertiser_statistical_analysis_management_router_url =
  "advertiser_statistical_analysis_management";
export const advertisement_statistical_analysis_management_router_url =
  "advertisement_statistical_analysis_management";
