import axios from "axios";
import axiosMiddleware from "./axios_middleware/axios_middleware";
import { moneymoa_server_router_url } from "../communication_router_url/communication_router_url";

export const administratorLogIn = async (email, password) => {
  try {
    const response = await axios.post(
      `${moneymoa_server_router_url}/administrator-auth/administrator-log-in`,
      {
        email,
        password,
      }
    );

    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
        administrator: response.data.administrator,
      };
    }
  } catch (error) {
    console.log("Error during login:", error.response.status);
    return {
      status: error.response.status,
    };
  }
};

export const administratorSignUp = async (
  email,
  password,
  name,
  phoneNumber,
  administratorRoleTypeId,
  certified,
  blocked
) => {
  try {
    const response = await axios.post(
      `${moneymoa_server_router_url}/administrator-auth/administrator-sign-up`,
      {
        email,
        password,
        name,
        phoneNumber,
        administratorRoleTypeId,
        certified,
        blocked,
      }
    );
    console.log(response);
    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
      };
    } else {
      console.error("Error during signup: Tokens not provided");
      return null;
    }
  } catch (error) {
    console.log("Error during signup:", error);
    return {
      status: error.response.status,
    };
  }
};

export const getUsersByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-user-management/get-users-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during getUsersByConditionsInAdmin:", error);
    return {
      status: error.response.status,
    };
  }
};

export const putUserInAdminUserManagement = async (
  userId,
  blocked,
  accumulatedPoint,
  leftPoint
) => {
  try {
    const response = await axiosMiddleware.put(
      `${moneymoa_server_router_url}/administrator-user-management/put-user-in-admin-user-management/${userId}`,
      {
        blocked,
        accumulatedPoint,
        leftPoint,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during putUserInAdminUserManagement:", error);
    return {
      status: error.response.status,
    };
  }
};

export const getAdvertisersByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-user-management/get-advertisers-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during getAdvertisersByConditionsInAdmin:", error);
    return {
      status: error.response.status,
    };
  }
};

export const putAdvertiserInAdminUserManagement = async (
  advertiserId,
  certified,
  blocked,
  accumulatedPoint,
  leftPoint
) => {
  try {
    const response = await axiosMiddleware.put(
      `${moneymoa_server_router_url}/administrator-user-management/put-advertiser-in-admin-user-management/${advertiserId}`,
      {
        certified,
        blocked,
        accumulatedPoint,
        leftPoint,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during putAdvertiserInAdminUserManagement:", error);
    return {
      status: error.response.status,
    };
  }
};

export const getAdministratorsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-user-management/get-administrators-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during getAdministratorsByConditionsInAdmin:", error);
    return {
      status: error.response.status,
    };
  }
};

export const putAdministratorInAdminUserManagement = async (
  certified,
  blocked,
  name,
  phoneNumber
) => {
  try {
    const response = await axiosMiddleware.put(
      `${moneymoa_server_router_url}/administrator-user-management/put-administrator-in-admin-user-management`,
      {
        certified,
        blocked,
        name,
        phoneNumber,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during putAdministratorInAdminUserManagement:", error);
    return {
      status: error.response.status,
    };
  }
};

export const getAdvertisementMissionDoingManagementsByConditionsInAdmin =
  async (searchTypeId, searchText, page, pageSize) => {
    try {
      const response = await axiosMiddleware.post(
        `${moneymoa_server_router_url}/administrator-advertisement-mission-doing-management/get-advertisement-mission-doing-managements-by-conditions-in-admin`,
        {
          searchTypeId,
          searchText,
          page,
          pageSize,
        }
      );

      return response;
    } catch (error) {
      console.log(
        "Error during getAdvertisementMissionDoingManagementsByConditionsInAdmin:",
        error
      );
      return {
        status: error.response.status,
      };
    }
  };

export const getAdministratorAdvertisementProductsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-advertisement-management/get-advertiser-advertisement-products-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during getAdministratorAdvertisementProductsByConditionsInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const postAdministratorAdvertisementProductInAdmin = async (
  administratorAdvertisementProductName,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  userPrice,
  advertisementProgressType
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-advertisement-management/post-administrator-advertisement-product-in-admin`,
      {
        administratorAdvertisementProductName,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        userPrice,
        advertisementProgressType,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during postAdministratorAdvertisementProductInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const putAdministratorAdvertisementProductInAdmin = async (
  administratorAdvertisementProductId,
  nextAdvertiserPrice,
  userPrice,
  advertisementProgressType
) => {
  try {
    const response = await axiosMiddleware.put(
      `${moneymoa_server_router_url}/administrator-advertisement-management/put-administrator-advertisement-product-in-admin/${administratorAdvertisementProductId}`,
      {
        nextAdvertiserPrice,
        userPrice,
        advertisementProgressType,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during putAdministratorAdvertisementProductInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const getAdvertiserAdvertisementManagementsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-advertisement-management/get-advertiser-advertisement-managements-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during getAdvertiserAdvertisementManagementsByConditionsInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const putAdvertiserAdvertisementManagementInAdmin = async (
  missionIntegratedId,
  nextAdvertisementProgressType
) => {
  try {
    const response = await axiosMiddleware.put(
      `${moneymoa_server_router_url}/administrator-advertisement-management/put-advertiser-advertisement-management-in-admin/${missionIntegratedId}`,
      {
        nextAdvertisementProgressType,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during putAdministratorAdvertisementProductInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const getUserPointHistoryManagementsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-point-history-management/get-user-point-history-managements-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during getUserPointHistoryManagementsByConditionsInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const getAdvertiserPointHistoryManagementsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-point-history-management/get-advertiser-point-history-managements-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during getAdvertiserPointHistoryManagementsByConditionsInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const getAdvertiserPaymentHistoryManagementsByConditionsInAdmin = async (
  searchTypeId,
  searchText,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      `${moneymoa_server_router_url}/administrator-payment-history-management/get-advertiser-payment-history-managements-by-conditions-in-admin`,
      {
        searchTypeId,
        searchText,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.log(
      "Error during getAdvertiserPaymentHistoryManagementsByConditionsInAdmin:",
      error
    );
    return {
      status: error.response.status,
    };
  }
};

export const putAdvertiserPaymentHistoryManagementPaymentCompleteInAdmin =
  async (advertiserPaymentHistoryId, paymentStateId, paymentCompletedAt) => {
    try {
      const response = await axiosMiddleware.put(
        `${moneymoa_server_router_url}/administrator-payment-history-management/put-advertiser-payment-history-management-payment-complete-in-admin/${advertiserPaymentHistoryId}`,
        {
          paymentStateId,
          paymentCompletedAt,
        }
      );

      return response;
    } catch (error) {
      console.log(
        "Error during putAdvertiserPaymentHistoryManagementPaymentCompleteInAdmin:",
        error
      );
      return {
        status: error.response.status,
      };
    }
  };
