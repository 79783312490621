import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px28rate,
  h_px6rate,
  w_px113rate,
  w_px11rate,
  w_px25rate,
  w_px26rate,
  w_px54rate,
  w_px5rate,
  w_px6rate,
  w_px8rate,
} from "../../../size";
import RadioBtn from "../../radio_btn/RadioBtn";
import DivisionWhiteBlueLine from "../../division_white_blue_line/DivisionWhiteBlueLine";
import DetailTextInput from "../../detail_text_input/DetailTextInput";
import SubTitle from "../../sub_title/SubTitle";
import ListName from "../../list_name/ListName";
import ContentText from "../../content_text/ContentText";
import WhiteBtn from "../../white_btn/WhiteBtn";
import PinkBtn from "../../pink_btn/PinkBtn";
import { putUserInAdminUserManagement } from "../../../../communication_system/axios_apis/axios_apis";
import { isOnlyNumber } from "../../../../data_system/data_functions/is_only_number";
import BlueBtn from "../../blue_btn/BlueBtn";
import { numberCommaFormatFunction } from "../../../../data_system/data_functions/number_comma_format_function";
import { dateWithTimeFormatFunction } from "../../../../data_system/data_functions/date_with_time_format_function";

const UserDetailComponent = ({
  choiceItem,
  setChoiceItem,
  setBodyContents,
}) => {
  console.log(choiceItem);
  const [loading, setLoading] = useState(true);

  const [changePoint, setChangePoint] = useState("");

  const [blocked, setBlocked] = useState(0);

  const onClickBlocked0 = useCallback(() => {
    setBlocked(0);
  }, []);

  const onClickBlocked1 = useCallback(() => {
    setBlocked(1);
  }, []);

  useEffect(() => {
    setLoading(true);

    setBlocked(
      choiceItem !== null && choiceItem !== undefined
        ? choiceItem.blocked
          ? 1
          : 0
        : 0
    );

    setLoading(false);
  }, [choiceItem]);

  if (loading) return <div>Loading...</div>;

  return (
    choiceItem !== null &&
    choiceItem !== undefined && (
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>프로필 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>회원 아이디</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{choiceItem.email}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이름</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{choiceItem.name}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>휴대폰 번호</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{choiceItem.phoneNumber}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>성별</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {choiceItem.sexTypeId === 1 ? "남성" : "여성"}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            marginBottom: h_px12rate,
            alignItems: "center",
          }}
        >
          <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
        </div>
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>추가 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>네이버 아이디</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{choiceItem.naverId}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>누적 포인트</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {numberCommaFormatFunction(choiceItem.accumulatedPoint)}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>사용 포인트</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {numberCommaFormatFunction(choiceItem.usedPoint)}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>남은 포인트</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {numberCommaFormatFunction(choiceItem.leftPoint)}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>포인트 변경</ListName>
          <div
            style={{
              display: "flex",
              marginLeft: w_px26rate,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <DetailTextInput
              width={w_px113rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"포인트를 입력해주세요."}
              value={changePoint}
              onChange={(e) => {
                if (
                  isOnlyNumber.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setChangePoint(e.target.value);
                }
              }}
            ></DetailTextInput>
            <div
              style={{
                marginLeft: w_px6rate,
              }}
            >
              <BlueBtn
                width={w_px54rate}
                height={h_px28rate}
                onClick={async () => {
                  if (changePoint === "") {
                    alert("포인트를 입력해주세요.");
                  } else {
                    const response = await putUserInAdminUserManagement(
                      choiceItem.id,
                      undefined,
                      choiceItem.accumulatedPoint + parseInt(changePoint),
                      choiceItem.leftPoint + parseInt(changePoint)
                    );

                    if (response.status === 200) {
                      alert("저장되었습니다.");
                      setBodyContents((prevContents) =>
                        prevContents.map((content) => {
                          if (content.id === choiceItem.id) {
                            return {
                              ...content,
                              accumulatedPoint:
                                choiceItem.accumulatedPoint +
                                parseInt(changePoint),
                              leftPoint:
                                choiceItem.leftPoint + parseInt(changePoint),
                            };
                          }
                          return content;
                        })
                      );
                      setChoiceItem((prevItem) => {
                        return {
                          ...prevItem,
                          accumulatedPoint:
                            choiceItem.accumulatedPoint + parseInt(changePoint),
                          leftPoint:
                            choiceItem.leftPoint + parseInt(changePoint),
                        };
                      });
                      setChangePoint("");
                    }
                  }
                }}
              >
                추가
              </BlueBtn>
            </div>
            <div
              style={{
                marginLeft: w_px6rate,
              }}
            >
              <BlueBtn
                width={w_px54rate}
                height={h_px28rate}
                onClick={async () => {
                  if (changePoint === "") {
                    alert("포인트를 입력해주세요.");
                  } else {
                    if (parseInt(changePoint) > choiceItem.leftPoint) {
                      alert("사용 가능한 포인트를 초과하였습니다.");
                    } else {
                      const response = await putUserInAdminUserManagement(
                        choiceItem.id,
                        undefined,
                        choiceItem.accumulatedPoint - parseInt(changePoint),
                        choiceItem.leftPoint - parseInt(changePoint)
                      );

                      if (response.status === 200) {
                        alert("저장되었습니다.");
                        setBodyContents((prevContents) =>
                          prevContents.map((content) => {
                            if (content.id === choiceItem.id) {
                              return {
                                ...content,
                                accumulatedPoint:
                                  choiceItem.accumulatedPoint -
                                  parseInt(changePoint),
                                leftPoint:
                                  choiceItem.leftPoint - parseInt(changePoint),
                              };
                            }
                            return content;
                          })
                        );
                        setChoiceItem((prevItem) => {
                          return {
                            ...prevItem,
                            accumulatedPoint:
                              choiceItem.accumulatedPoint -
                              parseInt(changePoint),
                            leftPoint:
                              choiceItem.leftPoint - parseInt(changePoint),
                          };
                        });
                        setChangePoint("");
                      }
                    }
                  }
                }}
              >
                차감
              </BlueBtn>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px6rate,
          }}
        >
          <div
            style={{
              marginRight: w_px26rate,
            }}
          >
            <ListName>차단 상태</ListName>
          </div>
          <div>
            <div
              style={{
                marginBottom: h_px6rate,
              }}
            >
              <RadioTotalFrame>
                <RadioFrame>
                  <RadioBtn
                    checked={parseInt(blocked) === 0 ? true : false}
                    onClick={onClickBlocked0}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>정상</ContentText>
                  </div>
                </RadioFrame>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <RadioFrame>
                    <RadioBtn
                      checked={parseInt(blocked) === 1 ? true : false}
                      onClick={onClickBlocked1}
                    ></RadioBtn>
                    <div
                      style={{
                        marginLeft: w_px5rate,
                      }}
                    >
                      <ContentText>차단</ContentText>
                    </div>
                  </RadioFrame>
                </div>
              </RadioTotalFrame>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이용약관</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {choiceItem.servicePolicyAgreed ? "동의" : "미동의"}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>개인정보처리방침</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {choiceItem.privacyPolicyAgreed ? "동의" : "미동의"}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>위치기반서비스</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {choiceItem.positionPolicyAgreed ? "동의" : "미동의"}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>마케딩 동의 여부</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {choiceItem.marketingPolicyAgreed ? "동의" : "미동의"}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>가입일</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {dateWithTimeFormatFunction(choiceItem.createdAt)}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: h_px12rate,
          }}
        >
          <WhiteBtn onClick={async () => {}}>취소</WhiteBtn>
          <div
            style={{
              marginLeft: w_px8rate,
            }}
          >
            <PinkBtn
              onClick={async () => {
                const response = await putUserInAdminUserManagement(
                  choiceItem.id,
                  blocked,
                  undefined,
                  undefined
                );

                if (response.status === 200) {
                  alert("수정되었습니다.");
                  setBodyContents((prevContents) =>
                    prevContents.map((content) => {
                      if (content.id === choiceItem.id) {
                        return {
                          ...content,
                          blocked: blocked,
                        };
                      }
                      return content;
                    })
                  );
                  setChoiceItem((prevItem) => {
                    return {
                      ...prevItem,
                      blocked: blocked,
                    };
                  });
                }
              }}
            >
              수정
            </PinkBtn>
          </div>
        </div>
      </div>
    )
  );
};

export default React.memo(UserDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
