import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  h_px6rate,
  w_px11rate,
  w_px160rate,
  w_px28rate,
  w_px8rate,
} from "../../design_system/size";
import {
  rgba_66_139_247_0d2,
  rgb_0_0_0,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../design_system/colors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/contexts/auth_context/auth_context";
import { sign_up_router_url } from "../../communication_system/inner_router_url/inner_router_url";
import { isOnlyNumber } from "../../data_system/data_functions/is_only_number";
import { emailCheckFunction } from "../../data_system/data_functions/email_check_function";
import { phoneNumberCheckFunction } from "../../data_system/data_functions/phone_number_check_function";

function SignUp() {
  let navigate = useNavigate();
  const { handleSignUp, logout } = useAuth();
  const [loading, setLoading] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    logout();
  }, []);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <Container>
      <Header>
        <HeaderRight>
          <BtnRounded
            type="button"
            onClick={() => {
              navigate("/");
            }}
          >
            로그인
          </BtnRounded>
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <BtnRounded
              type="button"
              onClick={() => {
                navigate(sign_up_router_url);
              }}
            >
              회원가입
            </BtnRounded>
          </div>
        </HeaderRight>
      </Header>
      <LoginContainer>
        <Input
          placeholder="아이디 이메일"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></Input>
        <Input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        ></Input>
        <Input
          type="password"
          placeholder="비밀번호 확인"
          value={passwordCheck}
          onChange={(e) => {
            setPasswordCheck(e.target.value);
          }}
        ></Input>
        <Input
          placeholder="이름"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        ></Input>
        <Input
          placeholder="휴대폰 번호 - 없이 입력"
          value={phoneNumber}
          onChange={(e) => {
            if (isOnlyNumber.test(e.target.value) || e.target.value === "") {
              setPhoneNumber(e.target.value);
            }
          }}
        ></Input>
        <Btn
          type="button"
          onClick={async () => {
            if (email === "") {
              alert("이메일을 입력해주세요.");
            } else {
              if (password === "") {
                alert("비밀번호를 입력해주세요.");
              } else {
                if (emailCheckFunction.test(email) === false) {
                  alert("이메일 형식이 올바르지 않습니다.");
                } else {
                  if (password !== passwordCheck) {
                    alert("비밀번호가 일치하지 않습니다.");
                  } else {
                    if (name === "") {
                      alert("이름을 입력해주세요.");
                    } else {
                      if (phoneNumber === "") {
                        alert("휴대폰 번호를 입력해주세요.");
                      } else {
                        if (
                          phoneNumberCheckFunction.test(phoneNumber) === false
                        ) {
                          alert("휴대폰 번호 형식이 올바르지 않습니다.");
                        } else {
                          const get = await handleSignUp(
                            email,
                            password,
                            name,
                            phoneNumber,
                            2,
                            false,
                            false
                          );
                          console.log(get);
                          if (get.status === 201) {
                            alert("회원가입 신청을 완료하였습니다.");
                            alert(
                              "승인 대기중입니다. 관리자 승인 후 로그인 가능합니다."
                            );
                            setEmail("");
                            setPassword("");
                            setPasswordCheck("");
                            setName("");
                            setPhoneNumber("");
                            navigate("/");
                          } else if (get.status === 400) {
                            alert("이미 가입된 이메일입니다.");
                          } else {
                            alert("회원가입에 실패하였습니다.");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }}
        >
          회원가입
        </Btn>
      </LoginContainer>
    </Container>
  );
}

export default React.memo(SignUp);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #f5f5f5;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  display: block;
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${w_px11rate}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const Btn = styled.button`
  display: block;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border-width: 0px;
  background-color: ${rgb_66_139_247};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
  cursor: pointer;
`;

const BtnRounded = styled.button`
  display: block;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border-width: 0px;
  background-color: ${rgb_66_139_247};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
  cursor: pointer;
  border-radius: 5px;
`;
