import React, { useEffect, useState, useCallback, useContext } from "react";
import { getUserPointHistoryManagementsByConditionsInAdmin } from "../../communication_system/axios_apis/axios_apis";
import styled from "styled-components";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import {
  h_px14rate,
  h_px22rate,
  h_px62d5rate,
  w_px11rate,
  w_px1226rate,
  w_px138rate,
  w_px139rate,
  w_px184rate,
  w_px4rate,
  w_px864rate,
} from "../../design_system/size";
import {
  rgb_0_0_0,
  rgb_205_205_205,
  rgb_255_255_255,
  rgba_66_139_247_0d05,
} from "../../design_system/colors";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import { MainRightSideTitle } from "../../design_system/components/main_right_side_title/MainRightSideTitle";
import Pagination from "../../design_system/components/pagination/Pagination";
import { numberCommaFormatFunction } from "../../data_system/data_functions/number_comma_format_function";
import { dateWithTimeFormatFunction } from "../../data_system/data_functions/date_with_time_format_function";

const UserPointHistoryManagement = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);

  const [
    userPointHistoryManagementDetailDropdownItem,
    setUserPointHistoryManagementDetailDropdownItem,
  ] = useState({
    id: 0,
    title: "회원 아이디",
    selected: false,
    value: 1,
  });

  const userPointHistoryManagementDetailList = [
    {
      id: 0,
      title: "회원 아이디",
      selected: false,
      value: 1,
    },
    {
      id: 1,
      title: "회원명",
      selected: false,
      value: 2,
    },
  ];

  const [page, setPage] = useState(1);

  const userPointHistoryManagementDetailResetThenSet = useCallback((item) => {
    setUserPointHistoryManagementDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const limit = 10;

  const structureInfo = [
    {
      id: 0,
      close_width: w_px184rate,
      open_width: w_px138rate,
    },
    {
      id: 1,
      close_width: w_px184rate,
      open_width: w_px138rate,
    },
    {
      id: 2,
      close_width: w_px184rate,
      open_width: w_px138rate,
    },
    {
      id: 3,
      close_width: w_px184rate,
      open_width: w_px138rate,
    },
    {
      id: 4,
      close_width: w_px184rate,
      open_width: w_px138rate,
    },
    {
      id: 5,
      close_width: w_px184rate,
      open_width: w_px139rate,
    },
    {
      id: 6,
      close_width: w_px184rate,
      open_width: w_px139rate,
    },
  ];

  const headerTitle = [
    {
      data: "회원 아이디",
    },
    {
      data: "회원명",
    },
    {
      data: "포인트 금액",
    },
    {
      data: "사유",
    },
    {
      data: "사유 승인 상태",
    },
    {
      data: "사유 완료 상태",
    },
    {
      data: "생성 일자",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const response = await getUserPointHistoryManagementsByConditionsInAdmin(
        userPointHistoryManagementDetailDropdownItem.value,
        searchText,
        page,
        10
      );
      console.log(response.data);

      setBodyContents(
        response.data.userPointHistoryManagementsByConditions.userPointHistories
      );
      setTotalPages(
        response.data.userPointHistoryManagementsByConditions.totalPages
      );
      setLoading(false);
    }

    fetchData();
  }, [page]);

  return (
    <>
      <MainRightSideTitle>회원 포인트 내역 관리</MainRightSideTitle>
      <>
        <SearchSection>
          <LongDropdown
            list={userPointHistoryManagementDetailList}
            resetThenSet={userPointHistoryManagementDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={async () => {
                setLoading(true);

                const response =
                  await getUserPointHistoryManagementsByConditionsInAdmin(
                    userPointHistoryManagementDetailDropdownItem.value,
                    searchText,
                    page,
                    10
                  );
                console.log(response.data);

                setBodyContents(
                  response.data.userPointHistoryManagementsByConditions
                    .userPointHistories
                );
                setTotalPages(
                  response.data.userPointHistoryManagementsByConditions
                    .totalPages
                );
                setLoading(false);
              }}
            ></SearchBar>
          </div>
        </SearchSection>
      </>
      {!loading && (
        <>
          {Array.isArray(bodyContents) && bodyContents.length > 0 ? (
            <TotalTableRowSection>
              <TotalTable itemOpen={itemOpen}>
                <TotalTableBody itemOpen={itemOpen}>
                  <TotalTableHeader>
                    {structureInfo.map((structure, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: itemOpen
                              ? structure["open_width"]
                              : structure["close_width"],
                            marginLeft: w_px11rate,
                            marginRight: w_px11rate,
                            boxSizing: "border-box",
                            wordBreak: "break-all",
                          }}
                        >
                          {headerTitle[index]["data"]}
                        </div>
                      );
                    })}
                  </TotalTableHeader>
                  {bodyContents.map((row, rowIndex) => {
                    return (
                      <TotalTableRow
                        key={rowIndex}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        rowIndex={rowIndex}
                        choiceRowIndex={choiceRowIndex}
                      >
                        {structureInfo.map((structure, colIndex) => {
                          const email = row.User.email;
                          const name = row.User.name;
                          const changedPoint = numberCommaFormatFunction(
                            row.changedPoint
                          );
                          const userPointReason =
                            row.userPointReasonId === 1
                              ? "미션 수행"
                              : row.userPointReasonId === 2
                              ? "환전"
                              : "결제";
                          const userPointReasonAgreedText =
                            row.userPointReasonAgreed === true
                              ? "승인"
                              : "미승인";
                          const userPointReasonCompletedText =
                            row.userPointReasonCompleted == true
                              ? "완료"
                              : "미완료";
                          const createdAt = dateWithTimeFormatFunction(
                            row.createdAt
                          );

                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {colIndex === 0
                                ? email
                                : colIndex === 1
                                ? name
                                : colIndex === 2
                                ? changedPoint
                                : colIndex === 3
                                ? userPointReason
                                : colIndex === 4
                                ? userPointReasonAgreedText
                                : colIndex === 5
                                ? userPointReasonCompletedText
                                : colIndex === 6
                                ? createdAt
                                : ""}
                            </TotalTableItem>
                          );
                        })}
                      </TotalTableRow>
                    );
                  })}
                </TotalTableBody>
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  setPage={setPage}
                  setItemOpen={setItemOpen}
                  setChoiceRowIndex={setChoiceRowIndex}
                  limit={limit}
                ></Pagination>
              </TotalTable>
            </TotalTableRowSection>
          ) : (
            <div>없음...</div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(UserPointHistoryManagement);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const TotalTableRowSection = styled.div`
  display: flex;
`;

const TotalTable = styled.div`
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableBody = styled.div`
  border-top: 1px solid ${rgb_0_0_0};
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${rgb_205_205_205};
  height: ${h_px62d5rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
`;

const TotalTableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${rgb_205_205_205};
  background-color: ${(props) =>
    props.choiceRowIndex === null || props.choiceRowIndex !== props.rowIndex
      ? rgb_255_255_255
      : rgba_66_139_247_0d05};
`;

const TotalTableItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${w_px11rate}px;
  width: ${(props) =>
    props.itemOpen
      ? props.structure["open_width"]
      : props.structure["close_width"]}px;
  font-family: noto_sans_kr_regular;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
  padding-left: ${w_px11rate}px;
  padding-right: ${w_px11rate}px;
  box-sizing: border-box;
  word-break: break-all;
`;
