import {
  administrator_router_url,
  user_router_url,
  advertiser_router_url,
  advertisement_product_management_router_url,
  user_point_history_management_router_url,
  advertiser_point_history_management_router_url,
  advertiser_payment_history_management_router_url,
  user_statistical_analysis_management_router_url,
  advertiser_statistical_analysis_management_router_url,
  advertisement_statistical_analysis_management_router_url,
  advertisement_mission_doing_management_router_url,
  advertiser_advertisement_management_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import Administrator from "../../pages/administrator/Administrator";
import AdvertisementMissionDoingManagement from "../../pages/advertisement_mission_doing_management/AdvertisementMissionDoingManagement";
import AdvertisementProductManagement from "../../pages/advertisement_product_management/AdvertisementProductManagement";
import AdvertisementStatisticalAnalysis from "../../pages/advertisement_statistical_analysis/AdvertisementStatisticalAnalysis";
import Advertiser from "../../pages/advertiser/Advertiser";
import AdvertiserAdvertisementManagement from "../../pages/advertiser_advertisement_management/AdvertiserAdvertisementManagement";
import AdvertiserPaymentHistoryManagement from "../../pages/advertiser_payment_history_management/AdvertiserPaymentHistoryManagement";
import AdvertiserPointHistoryManagement from "../../pages/advertiser_point_history_management/AdvertiserPointHistoryManagement";
import AdvertiserStatisticalAnalysis from "../../pages/advertiser_statistical_analysis/AdvertiserStatisticalAnalysis";
import User from "../../pages/user/User";
import UserPointHistoryManagement from "../../pages/user_point_history_management/UserPointHistoryManagement";
import UserStatisticalAnalysis from "../../pages/user_statistical_analysis/UserStatisticalAnalysis";

export const sidebar_data = [
  {
    mainId: 0,
    mainTitle: "이용자 관리",
    subTitleList: [
      {
        subId: 0,
        name: "회원",
        is_active: 1,
        router_url: user_router_url,
        components: <User></User>,
      },
      {
        subId: 1,
        name: "광고주",
        is_active: 0,
        router_url: advertiser_router_url,
        components: <Advertiser></Advertiser>,
      },
      {
        subId: 2,
        name: "관리자",
        is_active: 0,
        router_url: administrator_router_url,
        components: <Administrator></Administrator>,
      },
    ],
  },
  {
    mainId: 1,
    mainTitle: "광고 미션 수행 관리",
    subTitleList: [
      {
        subId: 0,
        name: "광고 미션 수행 관리",
        is_active: 0,
        router_url: advertisement_mission_doing_management_router_url,
        components: (
          <AdvertisementMissionDoingManagement></AdvertisementMissionDoingManagement>
        ),
      },
    ],
  },
  {
    mainId: 2,
    mainTitle: "광고 관리",
    subTitleList: [
      {
        subId: 0,
        name: "광고 상품 관리",
        is_active: 0,
        router_url: advertisement_product_management_router_url,
        components: (
          <AdvertisementProductManagement></AdvertisementProductManagement>
        ),
      },
      {
        subId: 0,
        name: "광고주 광고 관리",
        is_active: 0,
        router_url: advertiser_advertisement_management_router_url,
        components: (
          <AdvertiserAdvertisementManagement></AdvertiserAdvertisementManagement>
        ),
      },
    ],
  },
  {
    mainId: 3,
    mainTitle: "포인트 내역 관리",
    subTitleList: [
      {
        subId: 0,
        name: "회원 포인트 내역 관리",
        is_active: 0,
        router_url: user_point_history_management_router_url,
        components: <UserPointHistoryManagement></UserPointHistoryManagement>,
      },
      {
        subId: 1,
        name: "광고주 포인트 내역 관리",
        is_active: 0,
        router_url: advertiser_point_history_management_router_url,
        components: (
          <AdvertiserPointHistoryManagement></AdvertiserPointHistoryManagement>
        ),
      },
    ],
  },
  {
    mainId: 4,
    mainTitle: "결제 내역 관리",
    subTitleList: [
      {
        subId: 0,
        name: "광고주 결제 내역 관리",
        is_active: 0,
        router_url: advertiser_payment_history_management_router_url,
        components: (
          <AdvertiserPaymentHistoryManagement></AdvertiserPaymentHistoryManagement>
        ),
      },
    ],
  },
  {
    mainId: 5,
    mainTitle: "통계 분석",
    subTitleList: [
      {
        subId: 0,
        name: "회원 관련 통계 분석",
        is_active: 0,
        router_url: user_statistical_analysis_management_router_url,
        components: <UserStatisticalAnalysis></UserStatisticalAnalysis>,
      },
      {
        subId: 1,
        name: "광고주 관련 통계 분석",
        is_active: 0,
        router_url: advertiser_statistical_analysis_management_router_url,
        components: (
          <AdvertiserStatisticalAnalysis></AdvertiserStatisticalAnalysis>
        ),
      },
      {
        subId: 2,
        name: "광고 관련 통계 분석",
        is_active: 0,
        router_url: advertisement_statistical_analysis_management_router_url,
        components: (
          <AdvertisementStatisticalAnalysis></AdvertisementStatisticalAnalysis>
        ),
      },
    ],
  },
];
