import React from "react";
import axios from "axios";
import TotalRouter from "./router/Router";
import "moment/locale/ko";
import moment from "moment";
import { DeleteModalProvider } from "./data_system/contexts/modal_context/delete_modal_context";
import { AuthProvider } from "./data_system/contexts/auth_context/auth_context";
import { BodyContentsProvider } from "./data_system/contexts/body_contents_context/body_contents_context";

const App = () => {
  moment.locale("ko");
  axios.defaults.withCredentials = true;
  return (
    <div>
      <AuthProvider>
        <BodyContentsProvider>
          <DeleteModalProvider>
            <TotalRouter></TotalRouter>
          </DeleteModalProvider>
        </BodyContentsProvider>
      </AuthProvider>
    </div>
  );
};

export default React.memo(App);
