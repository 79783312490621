import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px28rate,
  h_px6rate,
  w_px113rate,
  w_px11rate,
  w_px25rate,
  w_px26rate,
  w_px54rate,
  w_px5rate,
  w_px6rate,
  w_px8rate,
} from "../../../size";
import RadioBtn from "../../radio_btn/RadioBtn";
import DivisionWhiteBlueLine from "../../division_white_blue_line/DivisionWhiteBlueLine";
import DetailTextInput from "../../detail_text_input/DetailTextInput";
import SubTitle from "../../sub_title/SubTitle";
import ListName from "../../list_name/ListName";
import ContentText from "../../content_text/ContentText";
import WhiteBtn from "../../white_btn/WhiteBtn";
import PinkBtn from "../../pink_btn/PinkBtn";
import BlueBtn from "../../blue_btn/BlueBtn";
import { isOnlyNumber } from "../../../../data_system/data_functions/is_only_number";
import {
  putAdvertiserInAdminUserManagement,
  putAdvertiserPaymentHistoryManagementPaymentCompleteInAdmin,
} from "../../../../communication_system/axios_apis/axios_apis";
import { numberCommaFormatFunction } from "../../../../data_system/data_functions/number_comma_format_function";
import { dateWithTimeFormatFunction } from "../../../../data_system/data_functions/date_with_time_format_function";

const AdvertiserPaymentHistoryManagementDetailComponent = ({
  choiceItem,
  setChoiceItem,
  setBodyContents,
}) => {
  console.log(choiceItem);
  const [loading, setLoading] = useState(true);

  const [changePoint, setChangePoint] = useState("");

  useEffect(() => {
    setLoading(true);

    setLoading(false);
  }, [choiceItem]);

  if (loading) return <div>Loading...</div>;

  return (
    <div
      style={{
        paddingTop: h_px25rate,
        paddingLeft: w_px25rate,
      }}
    >
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>사업자 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>광고주 아이디</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.email}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>상호(업체명) or 법인명(단체명)</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.businessName}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>사업자등록번호</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {choiceItem.Advertiser.businessRegistrationNumber}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>이름</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.advertiserName}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>휴대폰 번호</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.Advertiser.phoneNumber}</ContentText>
        </div>
      </div>
      <div
        style={{
          marginBottom: h_px12rate,
          alignItems: "center",
        }}
      >
        <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
      </div>
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>결제 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>입금자 정보</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{choiceItem.depositorInfo}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>결제 방법</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {choiceItem.paymentMethodTypeId === 1
              ? "무통장 입금"
              : choiceItem.paymentMethodTypeId === 2
              ? "카드결제"
              : "계좌이체"}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>결제 금액</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {numberCommaFormatFunction(choiceItem.totalPaymentPrice)}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>결제 상태</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {choiceItem.paymentStateId === 1
              ? "결제 신청"
              : choiceItem.paymentStateId === 2
              ? "결제 완료"
              : choiceItem.paymentStateId === 3
              ? "취소 신청"
              : choiceItem.paymentStateId === 4
              ? "취소 완료"
              : choiceItem.paymentStateId === 5
              ? "환불 신청"
              : choiceItem.paymentStateId === 6
              ? "환불 완료"
              : choiceItem.paymentStateId === 7
              ? "계산서발행 신청"
              : choiceItem.paymentStateId === 8
              ? "계산서발행 완료"
              : ""}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>생성 일자</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {dateWithTimeFormatFunction(choiceItem.createdAt)}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>결제 승인</ListName>
        <div
          style={{
            display: "flex",
            marginLeft: w_px26rate,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <BlueBtn
            width={w_px54rate}
            height={h_px28rate}
            onClick={async () => {
              if (choiceItem.paymentStateId === 1) {
                const response =
                  await putAdvertiserPaymentHistoryManagementPaymentCompleteInAdmin(
                    choiceItem.id,
                    2,
                    new Date()
                  );

                if (response.status === 200) {
                  alert("저장되었습니다.");
                  setBodyContents((prevContents) =>
                    prevContents.map((content) => {
                      if (content.id === choiceItem.id) {
                        return {
                          ...content,
                          paymentStateId: 2,
                        };
                      }
                      return content;
                    })
                  );
                  setChoiceItem((prevItem) => {
                    return {
                      ...prevItem,
                      paymentStateId: 2,
                    };
                  });
                  setChangePoint("");
                }
              } else {
                alert("이미 처리된 결제입니다.");
              }
            }}
          >
            결제 승인
          </BlueBtn>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: h_px12rate,
        }}
      >
        <WhiteBtn onClick={async () => {}}>취소</WhiteBtn>
        <div
          style={{
            marginLeft: w_px8rate,
          }}
        >
          <PinkBtn
            onClick={async () => {
              // const response = await putAdvertiserInAdminUserManagement(
              //   choiceItem.id,
              //   certified,
              //   blocked,
              //   undefined,
              //   undefined
              // );
              // if (response.status === 200) {
              //   alert("수정되었습니다.");
              //   setBodyContents((prevContents) =>
              //     prevContents.map((content) => {
              //       if (content.id === choiceItem.id) {
              //         return {
              //           ...content,
              //           certified: certified,
              //           blocked: blocked,
              //         };
              //       }
              //       return content;
              //     })
              //   );
              //   setChoiceItem((prevItem) => {
              //     return {
              //       ...prevItem,
              //       certified: certified,
              //       blocked: blocked,
              //     };
              //   });
              // }
            }}
          >
            수정
          </PinkBtn>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdvertiserPaymentHistoryManagementDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;
