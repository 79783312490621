import styled from "styled-components";
import { h_px35rate, w_px26rate } from "../../size";
import { rgb_0_0_0 } from "../../colors";

export const MainRightSideTitle = styled.div`
  font-size: ${w_px26rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_0_0_0};
  margin-bottom: ${h_px35rate}px;
`;
