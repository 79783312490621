import React, { useEffect, useState, useCallback, useContext } from "react";
import { getUsersByConditionsInAdmin } from "../../communication_system/axios_apis/axios_apis";
import styled from "styled-components";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import {
  h_px14rate,
  h_px17rate,
  h_px22rate,
  h_px4rate,
  h_px62d5rate,
  h_px64rate,
  h_px671d5rate,
  h_px735d5rate,
  w_px107rate,
  w_px108rate,
  w_px10rate,
  w_px11rate,
  w_px1226rate,
  w_px12rate,
  w_px143rate,
  w_px144rate,
  w_px20rate,
  w_px362rate,
  w_px4rate,
  w_px5rate,
  w_px864rate,
} from "../../design_system/size";
import {
  rgb_0_0_0,
  rgb_205_205_205,
  rgb_255_255_255,
  rgb_35_40_45,
  rgb_66_139_247,
  rgba_66_139_247_0d05,
} from "../../design_system/colors";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import Pagination from "../../design_system/components/pagination/Pagination";
import UserDetailComponent from "../../design_system/components/detail_components/user_detail_component/UserDetailComponent";
import { ArrowBackwardWhite1x } from "../../design_system/icons/icons";
import BodyScroll from "../../design_system/components/body_scroll/BodyScroll";
import { MainRightSideTitle } from "../../design_system/components/main_right_side_title/MainRightSideTitle";

const User = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalUsersNumber, setTotalUsersNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const [userDetailDropdownItem, setUserDetailDropdownItem] = useState({
    id: 0,
    title: "회원 아이디",
    selected: false,
    value: 1,
  });

  const userDetailList = [
    {
      id: 0,
      title: "회원 아이디",
      selected: false,
      value: 1,
    },
    {
      id: 1,
      title: "회원명",
      selected: false,
      value: 2,
    },
    {
      id: 2,
      title: "휴대폰 번호",
      selected: false,
      value: 3,
    },
  ];

  const [page, setPage] = useState(1);

  const userDetailResetThenSet = useCallback((item) => {
    setUserDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const [choiceItem, setChoiceItem] = useState(null);
  const limit = 10;

  const onClickRow = useCallback(
    (rowIndex) => {
      setChoiceRowIndex((prev) => {
        // 상태 업데이트를 새로운 상태 값에 기반하여 진행합니다.
        const isNewSelection = prev === null || prev !== rowIndex;
        const isDeselecting = prev === rowIndex;

        if (isNewSelection) {
          setChoiceItem(bodyContents[rowIndex]); // 새로운 아이템을 선택하는 경우
          setItemOpen(true);
        } else if (isDeselecting) {
          setItemOpen(false); // 선택한 아이템을 다시 클릭한 경우
        }

        // 새로운 상태를 반환하여 업데이트합니다.
        return isNewSelection ? rowIndex : null;
      });
      setRegisterOpen(false); // 이 상태 업데이트는 독립적으로 처리합니다.
    },
    [bodyContents]
  );

  const structureInfo = [
    {
      id: 0,
      close_width: w_px143rate,
      open_width: w_px107rate,
    },
    {
      id: 1,
      close_width: w_px143rate,
      open_width: w_px107rate,
    },
    {
      id: 2,
      close_width: w_px143rate,
      open_width: w_px107rate,
    },
    {
      id: 3,
      close_width: w_px143rate,
      open_width: w_px107rate,
    },
    {
      id: 4,
      close_width: w_px143rate,
      open_width: w_px108rate,
    },
    {
      id: 5,
      close_width: w_px143rate,
      open_width: w_px108rate,
    },
    {
      id: 6,
      close_width: w_px143rate,
      open_width: w_px108rate,
    },
    {
      id: 7,
      close_width: w_px143rate,
      open_width: w_px108rate,
    },
    {
      id: 8,
      close_width: w_px144rate,
      open_width: w_px108rate,
    },
  ];

  const headerTitle = [
    {
      data: "회원 아이디",
    },
    {
      data: "회원명",
    },
    {
      data: "휴대폰 번호",
    },
    {
      data: "성별",
    },
    {
      data: "네이버 아이디",
    },
    {
      data: "사용 포인트",
    },
    {
      data: "남은 포인트",
    },
    {
      data: "차단 상태",
    },
    {
      data: "광고수신 동의여부",
    },
  ];

  const close = useCallback(() => {
    setItemOpen(false);
    setRegisterOpen(false);
    setChoiceRowIndex(null);
  }, [setItemOpen, setRegisterOpen, setChoiceRowIndex]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const response = await getUsersByConditionsInAdmin(
        userDetailDropdownItem.value,
        searchText,
        page,
        10
      );
      console.log(response.data.usersByConditions);

      setBodyContents(response.data.usersByConditions.users);
      setTotalPages(response.data.usersByConditions.totalPages);
      setTotalUsersNumber(response.data.usersByConditions.totalCount);
      setLoading(false);
    }

    fetchData();
  }, [page]);

  return (
    <>
      <MainRightSideTitle>회원</MainRightSideTitle>
      <>
        <SearchSection>
          <LongDropdown
            list={userDetailList}
            resetThenSet={userDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={async () => {
                setLoading(true);

                const response = await getUsersByConditionsInAdmin(
                  userDetailDropdownItem.value,
                  searchText,
                  page,
                  10
                );
                console.log(response.data.usersByConditions);

                setBodyContents(response.data.usersByConditions.users);
                setTotalPages(response.data.usersByConditions.totalPages);
                setTotalUsersNumber(response.data.usersByConditions.totalCount);
                setLoading(false);
              }}
            ></SearchBar>
          </div>
        </SearchSection>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: w_px10rate,
            marginBottom: h_px17rate,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MiddleSectionBlackText>전체 회원</MiddleSectionBlackText>
            <div
              style={{
                marginLeft: w_px5rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MiddleSectionBlueText>{totalUsersNumber}</MiddleSectionBlueText>
              <MiddleSectionBlackText>명</MiddleSectionBlackText>
            </div>
          </div>
        </div>
      </>
      {!loading && (
        <>
          {Array.isArray(bodyContents) && bodyContents.length > 0 ? (
            <TotalTableRowSection>
              <TotalTable itemOpen={itemOpen}>
                <TotalTableBody itemOpen={itemOpen}>
                  <TotalTableHeader>
                    {structureInfo.map((structure, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: itemOpen
                              ? structure["open_width"]
                              : structure["close_width"],
                            marginLeft: w_px11rate,
                            marginRight: w_px11rate,
                            boxSizing: "border-box",
                            wordBreak: "break-all",
                          }}
                        >
                          {headerTitle[index]["data"]}
                        </div>
                      );
                    })}
                  </TotalTableHeader>
                  {bodyContents.map((row, rowIndex) => {
                    return (
                      <TotalTableRow
                        key={rowIndex}
                        onClick={(e) => {
                          e.preventDefault();
                          onClickRow(rowIndex);
                        }}
                        rowIndex={rowIndex}
                        choiceRowIndex={choiceRowIndex}
                      >
                        {structureInfo.map((structure, colIndex) => {
                          const email = row.email;
                          const name = row.name;
                          const phoneNumber = row.phoneNumber;
                          const sexType = row.sexTypeId === 1 ? "남" : "여";
                          const naverId = row.naverId;
                          const usedPoint = row.usedPoint;
                          const leftPoint = row.leftPoint;
                          const blecked = row.blocked ? "차단" : "정상";
                          const marketingPolicyAgreed =
                            row.marketingPolicyAgreed ? "동의" : "미동의";

                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {colIndex === 0
                                ? email
                                : colIndex === 1
                                ? name
                                : colIndex === 2
                                ? phoneNumber
                                : colIndex === 3
                                ? sexType
                                : colIndex === 4
                                ? naverId
                                : colIndex === 5
                                ? usedPoint
                                : colIndex === 6
                                ? leftPoint
                                : colIndex === 7
                                ? blecked
                                : marketingPolicyAgreed}
                            </TotalTableItem>
                          );
                        })}
                      </TotalTableRow>
                    );
                  })}
                </TotalTableBody>
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  setPage={setPage}
                  setItemOpen={setItemOpen}
                  setChoiceRowIndex={setChoiceRowIndex}
                  limit={limit}
                ></Pagination>
              </TotalTable>
              {itemOpen && (
                <TotalTableDetail>
                  <TotalTableDetailBodyFrame>
                    <div>
                      <HeaderTitle>
                        <HeaderArrowBackwardBtn
                          onClick={() => {
                            close();
                          }}
                        >
                          <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
                        </HeaderArrowBackwardBtn>
                        <div
                          style={{
                            marginBottom: h_px4rate,
                          }}
                        >
                          상세정보
                        </div>
                      </HeaderTitle>
                      <BodyScroll>
                        {!registerOpen ? (
                          <UserDetailComponent
                            choiceItem={choiceItem}
                            setChoiceItem={setChoiceItem}
                            setBodyContents={setBodyContents}
                          ></UserDetailComponent>
                        ) : (
                          <></>
                        )}
                      </BodyScroll>
                    </div>
                  </TotalTableDetailBodyFrame>
                </TotalTableDetail>
              )}
            </TotalTableRowSection>
          ) : (
            <div>없음...</div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(User);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const MiddleSectionBlackText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  font-family: noto_sans_kr_bold;
`;

const MiddleSectionBlueText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_66_139_247};
  font-family: noto_sans_kr_bold;
`;

const TotalTableRowSection = styled.div`
  display: flex;
`;

const TotalTable = styled.div`
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableBody = styled.div`
  border-top: 1px solid ${rgb_0_0_0};
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${rgb_205_205_205};
  height: ${h_px62d5rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
`;

const TotalTableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${rgb_205_205_205};
  background-color: ${(props) =>
    props.choiceRowIndex === null || props.choiceRowIndex !== props.rowIndex
      ? rgb_255_255_255
      : rgba_66_139_247_0d05};
`;

const TotalTableItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${w_px11rate}px;
  width: ${(props) =>
    props.itemOpen
      ? props.structure["open_width"]
      : props.structure["close_width"]}px;
  font-family: noto_sans_kr_regular;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
  padding-left: ${w_px11rate}px;
  padding-right: ${w_px11rate}px;
  box-sizing: border-box;
  word-break: break-all;
`;

const TotalTableDetail = styled.div`
  width: ${w_px362rate}px;
  height: ${h_px735d5rate}px;
`;

const TotalTableDetailBodyFrame = styled.div`
  height: ${h_px671d5rate}px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  width: ${w_px362rate}px;
  height: ${h_px64rate}px;
  font-size: ${w_px20rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
`;

const HeaderArrowBackwardBtn = styled.button`
  border-width: 0px;
  background-color: ${rgb_66_139_247};
`;
