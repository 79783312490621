import React, { useEffect, useState, useCallback, useContext } from "react";
import styled from "styled-components";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import {
  h_px14rate,
  h_px22rate,
  h_px62d5rate,
  w_px11rate,
  w_px121rate,
  w_px1226rate,
  w_px161rate,
  w_px4rate,
  w_px864rate,
} from "../../design_system/size";
import {
  rgb_0_0_0,
  rgb_205_205_205,
  rgb_255_255_255,
  rgba_66_139_247_0d05,
} from "../../design_system/colors";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import { MainRightSideTitle } from "../../design_system/components/main_right_side_title/MainRightSideTitle";
import { getAdvertiserPointHistoryManagementsByConditionsInAdmin } from "../../communication_system/axios_apis/axios_apis";
import Pagination from "../../design_system/components/pagination/Pagination";
import { numberCommaFormatFunction } from "../../data_system/data_functions/number_comma_format_function";
import { dateWithTimeFormatFunction } from "../../data_system/data_functions/date_with_time_format_function";

const AdvertiserPointHistoryManagement = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);

  const [
    advertiserPointHistoryManagementDetailDropdownItem,
    setAdvertiserPointHistoryManagementDetailDropdownItem,
  ] = useState({
    id: 0,
    title: "광고주 아이디",
    selected: false,
    value: 1,
  });

  const advertiserPointHistoryManagementDetailList = [
    {
      id: 0,
      title: "광고주 아이디",
      selected: false,
      value: 1,
    },
    {
      id: 1,
      title: "상호(업체명) or 법인명(단체명)",
      selected: false,
      value: 2,
    },
    {
      id: 2,
      title: "사업자등록번호",
      selected: false,
      value: 3,
    },
  ];

  const [page, setPage] = useState(1);

  const advertiserPointHistoryManagementDetailResetThenSet = useCallback(
    (item) => {
      setAdvertiserPointHistoryManagementDetailDropdownItem(item);
    },
    []
  );

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const limit = 10;

  const structureInfo = [
    {
      id: 0,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 1,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 2,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 3,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 4,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 5,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 6,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
    {
      id: 7,
      close_width: w_px161rate,
      open_width: w_px121rate,
    },
  ];

  const headerTitle = [
    {
      data: "광고주 아이디",
    },
    {
      data: "상호(업체명) or 법인명(단체명)",
    },
    {
      data: "사업자등록번호",
    },
    {
      data: "포인트 금액",
    },
    {
      data: "사유",
    },
    {
      data: "결제 상태",
    },
    {
      data: "광고 상품명",
    },
    {
      data: "생성 일자",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const response =
        await getAdvertiserPointHistoryManagementsByConditionsInAdmin(
          advertiserPointHistoryManagementDetailDropdownItem.value,
          searchText,
          page,
          10
        );
      console.log(response.data);

      setBodyContents(
        response.data.advertiserPointHistoryManagementsByConditions
          .advertiserPointHistories
      );
      setTotalPages(
        response.data.advertiserPointHistoryManagementsByConditions.totalPages
      );
      setLoading(false);
    }

    fetchData();
  }, [page]);

  return (
    <>
      <MainRightSideTitle>광고주 포인트 내역 관리</MainRightSideTitle>
      <>
        <SearchSection>
          <LongDropdown
            list={advertiserPointHistoryManagementDetailList}
            resetThenSet={advertiserPointHistoryManagementDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={async () => {
                setLoading(true);

                const response =
                  await getAdvertiserPointHistoryManagementsByConditionsInAdmin(
                    advertiserPointHistoryManagementDetailDropdownItem.value,
                    searchText,
                    page,
                    10
                  );
                console.log(response.data);

                setBodyContents(
                  response.data.advertiserPointHistoryManagementsByConditions
                    .advertiserPointHistories
                );
                setTotalPages(
                  response.data.advertiserPointHistoryManagementsByConditions
                    .totalPages
                );
                setLoading(false);
              }}
            ></SearchBar>
          </div>
        </SearchSection>
      </>
      {!loading && (
        <>
          {Array.isArray(bodyContents) && bodyContents.length > 0 ? (
            <TotalTableRowSection>
              <TotalTable itemOpen={itemOpen}>
                <TotalTableBody itemOpen={itemOpen}>
                  <TotalTableHeader>
                    {structureInfo.map((structure, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: itemOpen
                              ? structure["open_width"]
                              : structure["close_width"],
                            marginLeft: w_px11rate,
                            marginRight: w_px11rate,
                            boxSizing: "border-box",
                            wordBreak: "break-all",
                          }}
                        >
                          {headerTitle[index]["data"]}
                        </div>
                      );
                    })}
                  </TotalTableHeader>
                  {bodyContents.map((row, rowIndex) => {
                    return (
                      <TotalTableRow
                        key={rowIndex}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        rowIndex={rowIndex}
                        choiceRowIndex={choiceRowIndex}
                      >
                        {structureInfo.map((structure, colIndex) => {
                          const email = row.Advertiser.email;
                          const businessName = row.Advertiser.businessName;
                          const businessRegistrationNumber =
                            row.Advertiser.businessRegistrationNumber;
                          const totalPoint = numberCommaFormatFunction(
                            row.totalPoint
                          );
                          const advertiserPointReasonName =
                            row.advertiserPointReasonId === 1
                              ? "충전"
                              : row.advertiserPointReasonId === 2
                              ? "사용"
                              : row.advertiserPointReasonId === 3
                              ? "환불"
                              : "";

                          const paymentStateName =
                            row.AdvertiserPaymentHistory !== null &&
                            row.AdvertiserPaymentHistory.paymentStateId === 1
                              ? "결제 신청"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                2
                              ? "결제 완료"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                3
                              ? "취소 신청"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                4
                              ? "취소 완료"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                5
                              ? "환불 신청"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                6
                              ? "환불 완료"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                7
                              ? "계산서발행 신청"
                              : row.AdvertiserPaymentHistory.paymentStateId ===
                                8
                              ? "계산서발행 완료"
                              : "";

                          const administratorAdvertisementProductName =
                            row.administratorAdvertisementProductId !== null &&
                            row.administratorAdvertisementProductId === 1
                              ? "N스토어 트래픽 - 단독"
                              : row.administratorAdvertisementProductId === 2
                              ? "N스토어 트래픽 - 가격비교"
                              : row.administratorAdvertisementProductId === 3
                              ? "N스토어 알림"
                              : row.administratorAdvertisementProductId === 4
                              ? "N스토어 상품 찜"
                              : row.administratorAdvertisementProductId === 5
                              ? "N플레이스 트래픽"
                              : row.administratorAdvertisementProductId === 6
                              ? "N플레이스 저장"
                              : row.administratorAdvertisementProductId === 7
                              ? "N플레이스 알림"
                              : row.administratorAdvertisementProductId === 8
                              ? "N플레이스 지도 찜"
                              : row.administratorAdvertisementProductId === 9
                              ? "N플레이스 블로그 공유"
                              : row.administratorAdvertisementProductId === 10
                              ? "N플레이스 Keep 공유"
                              : row.administratorAdvertisementProductId === 11
                              ? "N플레이스 블로그 리뷰"
                              : row.administratorAdvertisementProductId === 12
                              ? "카카오 선물 위시"
                              : row.administratorAdvertisementProductId === 13
                              ? "카카오 선물 후기 좋아요"
                              : row.administratorAdvertisementProductId === 14
                              ? "카카오 맵 저장"
                              : row.administratorAdvertisementProductId === 15
                              ? "구글 맵 저장"
                              : row.administratorAdvertisementProductId === 16
                              ? "구글 맵 리뷰"
                              : row.administratorAdvertisementProductId === 17
                              ? "인스타 팔로우"
                              : row.administratorAdvertisementProductId === 18
                              ? "인스타 좋아요"
                              : row.administratorAdvertisementProductId === 19
                              ? "인스타 저장"
                              : row.administratorAdvertisementProductId === 20
                              ? "유튜브 구독"
                              : row.administratorAdvertisementProductId === 21
                              ? "유튜브 좋아요"
                              : row.administratorAdvertisementProductId === 22
                              ? "배민 찜"
                              : row.administratorAdvertisementProductId === 23
                              ? "배민 원 찜"
                              : "";

                          const createdAt = dateWithTimeFormatFunction(
                            row.createdAt
                          );

                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {colIndex === 0
                                ? email
                                : colIndex === 1
                                ? businessName
                                : colIndex === 2
                                ? businessRegistrationNumber
                                : colIndex === 3
                                ? totalPoint
                                : colIndex === 4
                                ? advertiserPointReasonName
                                : colIndex === 5
                                ? paymentStateName
                                : colIndex === 6
                                ? administratorAdvertisementProductName
                                : createdAt}
                            </TotalTableItem>
                          );
                        })}
                      </TotalTableRow>
                    );
                  })}
                </TotalTableBody>
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  setPage={setPage}
                  setItemOpen={setItemOpen}
                  setChoiceRowIndex={setChoiceRowIndex}
                  limit={limit}
                ></Pagination>
              </TotalTable>
            </TotalTableRowSection>
          ) : (
            <div>없음...</div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(AdvertiserPointHistoryManagement);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const TotalTableRowSection = styled.div`
  display: flex;
`;

const TotalTable = styled.div`
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableBody = styled.div`
  border-top: 1px solid ${rgb_0_0_0};
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${rgb_205_205_205};
  height: ${h_px62d5rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
`;

const TotalTableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${rgb_205_205_205};
  background-color: ${(props) =>
    props.choiceRowIndex === null || props.choiceRowIndex !== props.rowIndex
      ? rgb_255_255_255
      : rgba_66_139_247_0d05};
`;

const TotalTableItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${w_px11rate}px;
  width: ${(props) =>
    props.itemOpen
      ? props.structure["open_width"]
      : props.structure["close_width"]}px;
  font-family: noto_sans_kr_regular;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
  padding-left: ${w_px11rate}px;
  padding-right: ${w_px11rate}px;
  box-sizing: border-box;
  word-break: break-all;
`;
